<template>
  <div v-if="events?.length" class="flex flex-col !overflow-y-hidden px-4">
    <div
      v-for="event in events"
      :key="event.id"
      class="relative flex min-h-12 flex-col gap-2 border-l border-gray-300 pb-4 pl-8 pt-1 text-gray-500"
    >
      <Tippy
        class="!absolute -left-3 top-1 flex h-6 w-6 cursor-help items-center justify-center rounded-full bg-gray-100"
        :content="LegalEventCategory[event.category] ?? 'Uncategorized'"
        placement="bottom"
      >
        <span
          v-if="event.category && event.category !== 'undefined'"
          class="text-sm"
        >
          {{ event.category?.[0] ?? 'U' }}
        </span>
        <Icon v-else :name="'tag'" class="h-4 w-4 text-cyan-900" />
      </Tippy>
      <div class="flex items-center justify-between">
        <span class="text-sm font-medium capitalize">
          {{ event.description.toLocaleLowerCase() }}
        </span>
        <span class="ml-2 min-w-20 text-xs text-gray-500">
          {{ relativeTime(event.gazetteDate) }}
        </span>
      </div>
      <slot :="{ i: index, event }">
        <div class="flex flex-col gap-y-2">
          <NuxtLink
            v-if="event.patent"
            :to="`/patents/${event.patent.id}`"
            class="flex flex-col gap-y-0.5 text-xs hover:text-cyan-800"
          >
            <span>{{ event.patent.family.title }}</span>
            <span>#{{ event.patent.publicationNumber }}</span>
          </NuxtLink>
        </div>
      </slot>
    </div>
    <div ref="loader" class="flex w-full justify-center">
      <TwSpinner v-if="hasMore" class="h-5 w-5 animate-spin text-cyan-800" />
    </div>
  </div>
  <Empty v-else class="text-cyan-900" />
</template>

<script setup lang="ts">
// defines
const props = defineProps<{ events?: PatentLegalEvent[]; hasMore?: boolean }>()
const emit = defineEmits(['more'])

// data
const events = computed(() => props.events ?? [])
const loader = useOnIntersect(() => emit('more'), { rootMargin: '10px' })
</script>
